import { createRouter, createWebHashHistory } from 'vue-router'

let routes = [
    { path: '/', name: 'index', component: () => import('@/pages/index'), meta: { title: '首页' } },
    { path: '/report', name: 'report', component: () => import('@/pages/report'), meta: { title: '报表' } },
]

const router = createRouter({
    history: createWebHashHistory(),
    mode: "hash",
    routes
})

export default router
