<template>
  <a-config-provider>
    <router-view />
  </a-config-provider>
</template>

<script>

export default {
  name: 'App',
  components: {
    // Login
  }
}
</script>

<style lang="less">
@import "@/theme/style.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}
</style>
